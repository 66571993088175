<template>
  <div class="inputLogin">
    <a-form @submit="handleSubmit">
      <!-- <label>NIS/NIK</label> -->
      <a-input v-model="username" class="form-input" type="text" placeholder="M-NIS (Student) or G-NIK (Teacher)" />
      <br />
      <!-- <a-row type="flex">
        <a-col :span="12">
          <label>Password</label>
        </a-col>
        <a-col
          :span="12"
          class="d-flex justify-content-end"
          style="color: #ff0000"
        >
          <p class="forgot" style="color: #ff0000" @click.prevent="toForgot">
            Forgot your password?
          </p>
        </a-col>
      </a-row> -->
      <a-input-password placeholder="Password" v-model="password" />
      <!-- <a-button size="large" class="form-submit" html-type="submit"
        >LOGIN</a-button
      > -->
      <a-button type="primary" size="large" html-type="submit" block>Login</a-button>
      <p class="forgot text-primary mt-3" @click.prevent="toForgot">
        Forgot your password?
      </p>
    </a-form>
  </div>
</template>
<script>
import jwt from 'jsonwebtoken'
export default {
  name: 'InputLogin',
  data() {
    return {
      username: '',
      password: '',
      objToken: {},
    }
  },
  methods: {
    toForgot() {
      this.$router.push({ name: 'Forgot Password' })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (!this.username || !this.password) {
        this.$notification.error({
          message: 'NIK/NIS or Password is empty.',
          description: 'Please write your NIK/NIS and your password.',
        })
      } else {
        if (this.username.split('-')[0] === 'G' || this.username.split('-')[0] === 'M' || this.username.split('-')[0] === 'C' || this.username.includes('admin')) {
          this.$store.dispatch('user/LOGIN', {
            username: this.username,
            password: this.password,
            token: this.objToken,
          })
            .then(({ isError, nama, role, status }) => {
              // console.log(isError, 'ayeaye')
              if (isError) {
                if (status === 'Too Many Requests') {
                  this.$notification.error({
                    message: 'Your IP has been blocked.',
                    description: 'Please try 30 minutes later.',
                  })
                } else {
                  this.$notification.error({
                    message: 'Wrong NIK/NIS or password',
                    description: status || 'Try Again. Please check your NIK/NIS or password.',
                  })
                }
              } else {
                if (role.includes('guruMapel')) {
                  this.$notification.success({
                    message: `Welcome ${nama}!`,
                    description: 'Enjoy the class.',
                  })
                } else if (role.includes('admin')) {
                  this.$notification.success({
                    message: `Welcome ${nama}!`,
                  })
                } else {
                  this.$notification.success({
                    message: `Welcome ${nama}!`,
                    description: 'Enjoy the class.',
                  })
                }
              }
            })
        } else {
          this.$notification.error({
            message: 'Wrong First Code',
            description: "Please write 'G-' (teacher), 'M-' (student) or 'C-' (Candidate) before you add your NIK/NIS.",
          })
        }
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.user.isLoading
    },
    isWrong() {
      return this.$store.state.user.isWrong
    },
  },
  async created() {
    if (this.$route.query?.token) {
      this.objToken = await jwt.verify(this.$route.query.token, 'ujian-hasan-digital')
      // this.username = this.$route.query.token
    }
  },
}
</script>
<style lang="scss">
.inputLogin {
  label {
    margin-bottom: 10px;
  }

  .forgot {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .form-input {
    margin-bottom: 30px;
    font-size: 16px;
    /* width: 475px; */
    width: 100%;
    height: 48px;
    padding: 10px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-e3eaf7);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e3eaf7;
    border-radius: 4px;
    opacity: 1;
    color: #041930;
  }

  .form-input:focus {
    border-color: var(--kit-color-primary);
  }

  .form-input:hover {
    border-color: var(--kit-color-primary);
  }

  // input:focus {
  //   outline: none;
  // }

  // input[type="text"]:focus {
  //   outline: #3a9a9b !important;
  // }

  // input[type="text"] {
  //   outline: #3a9a9b !important;
  // }

  .form-submit {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    padding: 10px 45px;
    // background: #3a9a9b 0% 0% no-repeat padding-box;
    background: var(--kit-color-primary) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    border-style: none;
    color: #ffffff;
  }

  .form-submit:active {
    border-style: none;
  }

  .form-submit:hover {
    opacity: 0.9;
    transition: all 0.2s;
  }

  .ant-input-password {
    .ant-input {
      margin-bottom: 30px;
      font-size: 16px;
      /* width: 475px; */
      width: 100%;
      height: 48px;
      padding: 10px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      border: 1px solid var(--unnamed-color-e3eaf7);
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e3eaf7;
      border-radius: 4px;
      opacity: 1;
      color: #041930;

      &:focus {
        border-color: var(--kit-color-primary);
      }
    }

    .ant-input-suffix {
      top: 33%;
    }
  }

  .ant-input-affix-wrapper {
    font-size: 18px;
    // color: #041930;
  }
}
</style>
